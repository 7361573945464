import React, { useState, useRef, useEffect } from "react"
import {
  SENTRY_ACTIONS,
  is3DSChargifyError,
  Icon,
  Spinner,
  CURRENT_COUNTRY,
  getSessionStorage,
  captureException,
  getChargifyErrorPrismic,
  shouldShowOriginalBraintreeError,
} from "@lesmills/gatsby-theme-common"
import { PROCESSING_VERIFY_CARD } from "../../constants/chargify-type"

type Props = {|
  prismicData: Object,
  securityChargifyInfo: Object,
  onGetChargifySuccess: () => void | Promise<void>,
  handleCardErrors: () => void | Promise<void>,
  setIsChangedPayment?: () => void | Promise<void>,
  setIsExpandedPaymentPopup?: () => void | Promise<void>,
  isLoading?: Boolean,
  currentPaymentUser: Object,
  isEnabled3DS: Boolean,
  setIsExpandedCreditCard: () => void | Promise<void>,
  isDisabledPayPal: Boolean,
|}

let chargify
let chargifyPaypal

const ChargifyDropIn = ({
  prismicData,
  securityChargifyInfo,
  onGetChargifySuccess,
  handleCardErrors,
  isLoading,
  setIsChangedPayment = () => {},
  currentPaymentUser = null,
  setIsExpandedPaymentPopup = () => {},
  isEnabled3DS = false,
  setIsExpandedCreditCard = () => {},
  isDisabledPayPal = false,
}: Props) => {
  const chargifyForm = useRef(null)
  const [isCreditCard, setIsCreditCard] = useState(false)
  const [isPaypal, setIsPaypal] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const isExpandedPopup = (isCreditCard || isPaypal) && !isLoading

  const currentCountry = getSessionStorage(CURRENT_COUNTRY)

  const [successValidatedPayment, setSuccessValidatedPayment] = useState(null)

  const [isHiddenOptions, setIsHiddenOptions] = useState(null)

  const { securityToken, subdomain, publicKey } = securityChargifyInfo || {}

  const {
    choose_another_way_text = {},
    choose_way_to_pay_text = {},
    credit_card_number_label = {},
    credit_card_option_label = {},
    paypal_option_label = {},
    cvv_label = {},
    field_is_not_valid_error = {},
    pay_with_credit_card_text = {},
    month_label = {},
    year_label = {},
    processing_verify_credit_card_text = {},
    paying_with_text = {},
  } = prismicData
  const fieldIsNotValidText = field_is_not_valid_error.text

  const paypalButtonRef = useRef(null)

  setIsExpandedPaymentPopup(isExpandedPopup)

  useEffect(() => {
    return () => {
      // https://lesmillsinternational.atlassian.net/browse/LA-2079: Reset credit card when component unmounts
      chargify && chargify.unload()
    }
  }, [])

  const handleOpenCreditCardPopup = () => {
    chargifyPaypal && chargifyPaypal.unload()
    setIsExpandedCreditCard(true)
    setIsCreditCard(true)
    setIsChangedPayment(true)
    // Avoid new Chargify many time, fix issue when switch pages or open popup many time
    if (!chargify) {
      chargify = new window.Chargify()
    }
    chargify && securityToken
    chargify.load(
      {
        publicKey: publicKey,
        serverHost: `https://${subdomain}.chargify.com`, // https://lesmills-dev-de.chargify.com
        securityToken: securityToken,
        threeDSecure: isEnabled3DS,
        type: "card",
        fields: {
          number: {
            selector: "#chargify-card-number",
            label: credit_card_number_label.text,
            placeholder: "**** **** **** ****",
            message: fieldIsNotValidText,
          },
          month: {
            selector: "#chargify-card-month",
            label: month_label.text,
            placeholder: "MM",
            message: fieldIsNotValidText,
          },
          year: {
            selector: "#chargify-card-year",
            label: year_label.text,
            placeholder: "YYYY",
            message: fieldIsNotValidText,
          },
          cvv: {
            selector: "#chargify-card-cvv",
            label: cvv_label.text,
            placeholder: "***",
            required: true,
            message: fieldIsNotValidText,
          },
        },
        style: {
          input: {
            paddingTop: "2px",
            paddingBottom: "1px",
            placeholder: { color: "#7f7f7f" },
            borderRadius: "unset",
            fontSize: "16px",
            border: "1px solid #b5b5b5",
          },
          label: {
            fontSize: "14px",
            fontWeight: "normal",
            lineHeight: "1.4",
          },
        },
      },
      {
        onThreeDsConfigError: error => {
          captureException({
            action: SENTRY_ACTIONS.THREE_D_SECURE_CONFIG,
            ...error,
          })
        },
      }
    )
  }

  const handleOpenPaypalPopup = async () => {
    setIsPaypal(true)
    // Avoid new Chargify many time, fix issue when switch pages or open popup many time
    if (!chargifyPaypal) {
      chargifyPaypal = new window.Chargify()
    }

    // If paypal section is hidden, just show it. No need reload
    if (document.getElementById("paypal-button").children.length === 0) {
      chargifyPaypal && securityToken
      chargifyPaypal.load(
        {
          publicKey: publicKey,
          serverHost: `https://${subdomain}.chargify.com`, // https://lesmills-dev-de.chargify.com
          type: "pay_pal",
          securityToken: securityToken,
          selector: "#paypal-chargify",
          selectorForPayPalButton: "#paypal-button",
        },
        {
          // this function is called after PayPal authorization passes (i.e. user has completed PayPal flow)
          onPayPalAuthorized: () => {
            chargifyPaypal.token(
              document.querySelector(".paypal-host-form"),

              token => {
                setIsChangedPayment(true)
                onGetChargifySuccess({
                  chargifyToken: token,
                  paymentType: "PayPalAccount",
                  handleAfterUpdatePayment: () => {
                    handleOpenChooseOptions()
                    setIsPaypal(false)
                    setSuccessValidatedPayment(null)
                  },
                })
                setSuccessValidatedPayment("PayPal")
                // Need unload Paypal in case success
                chargifyPaypal.unload()
                setIsHiddenOptions(true)
              },

              err => {
                const error = err && getPrimsicError(err)
                handleCardErrors(error, SENTRY_ACTIONS.VERIFY_CHARGIFY_PAYPAL)
              }
            )
          },
          onPayPalError: err => {
            // Fix sometime PP button not display when have error
            if (
              paypalButtonRef.current &&
              paypalButtonRef.current.children.length > 0
            ) {
              paypalButtonRef.current.style.display = "flex"
            }

            captureException({
              action: SENTRY_ACTIONS.PAYPAL_ERROR,
              ...err,
            })
          },
        }
      )
    } else {
      paypalButtonRef.current.style.display = "flex" // show paypal button
    }
  }

  const getPrimsicError = err => {
    const isShowOriginalError = shouldShowOriginalBraintreeError(err.errors)
    if (isShowOriginalError) {
      return {
        message: err.errors,
        type: "",
      }
    } else {
      let prismicError = err.message
        ? prismicData[getChargifyErrorPrismic(err.message)]
        : prismicData["saving_payment_method_error"]
      let errorType = ""
      const errors = err.errors

      if (errors && errors.length > 0) {
        if (
          Array.isArray(errors) &&
          errors
            .join()
            .toLowerCase()
            .includes(
              "cannot call verifycard while existing authentication is in progress"
            )
        ) {
          prismicError = processing_verify_credit_card_text
          errorType = PROCESSING_VERIFY_CARD
        } else {
          const errorMessageEN = Array.isArray(errors)
            ? errors[1] || errors[0]
            : errors
          prismicError = prismicData[getChargifyErrorPrismic(errorMessageEN)]
        }
      }

      return {
        message: prismicError && prismicError.text,
        type: errorType,
      }
    }
  }

  const handleSubmitCreditCardForm = event => {
    event.preventDefault()

    setIsProcessing(true)
    chargify &&
      chargify.token(
        chargifyForm.current,
        token => {
          setIsChangedPayment(true)
          onGetChargifySuccess(
            {
              chargifyToken: token,
              paymentType: "CreditCard",
            },
            () => {
              handleOpenChooseOptions()
              setSuccessValidatedPayment(null)
            }
          )
          setIsProcessing(false)
        },
        err => {
          const error = err && getPrimsicError(err)
          handleCardErrors(
            error,
            is3DSChargifyError(error.message)
              ? SENTRY_ACTIONS.VERIFY_THREE_D_SECURE_CHARGIFY_CREDIT_CARD
              : SENTRY_ACTIONS.VERIFY_CHARGIFY_CREDIT_CARD,
            err // The origin error returned from chargify
          )

          if (error.type !== PROCESSING_VERIFY_CARD) {
            setIsProcessing(false)
          }
        }
      )
  }

  const handleOpenChooseOptions = () => {
    if (isCreditCard) {
      chargify && chargify.unload()
      setIsCreditCard(false)
      if (successValidatedPayment !== "PayPal") {
        // don't reset if user have lasted method is PP
        setIsChangedPayment(false)
      }
      setIsExpandedCreditCard(false)
    } else if (isPaypal) {
      // unload chargifyPaypal if click choose another when paypal button is loading
      if (
        chargifyPaypal &&
        document.getElementById("paypal-button").children.length === 0
      ) {
        chargifyPaypal.unload()
      }
      setIsPaypal(false)
    }
    setIsHiddenOptions(false)
  }

  const { type = "CreditCard", last4 = null, email = null, cardType = "visa" } =
    currentPaymentUser || {}
  return (
    <div className="w-full pb-30">
      <div className={`${currentPaymentUser ? "my-20" : "hidden"}`}>
        <p className="pb-10">{`${paying_with_text.text} ${
          cardType === "paypal" || type === "PayPalAccount"
            ? paypal_option_label.text
            : credit_card_option_label.text
        }`}</p>
        <div
          className={`${
            currentPaymentUser
              ? "border-2 border-green-520 rounded flex justify-between p-15"
              : "hidden"
          }`}
        >
          {type === "CreditCard" && (
            <div className="flex items-center">
              <Icon
                icon={cardType.toLowerCase()} // fix in some country cardType icon not show
                type="base"
                classNames="w-40 h-icon-base mr-15"
              />
              <div className="flex flex-col">
                <p className="font-bold">{`Ending in ${last4}`}</p>
                <p className="capitalize text-3xs">{cardType}</p>
              </div>
            </div>
          )}
          {type === "PayPalAccount" && (
            <div className="flex items-center">
              <Icon
                icon="paypal"
                type="base"
                classNames="w-40 h-icon-base mr-15"
              />
              <div className="flex flex-col lg:w-full md:w-250 w-logo-lmod-lg">
                <p className="font-bold truncate">{email}</p>
                <p className="capitalize text-3xs">
                  {paypal_option_label.text}
                </p>
              </div>
            </div>
          )}
          {type === "KlarnaAccount" && (
            <div className="flex items-center">
              <Icon
                icon="klarna"
                type="base"
                classNames="w-46 h-icon-base mr-15"
              />
              <div className="flex flex-col">
                <p className="font-bold">{`Klarna`}</p>
              </div>
            </div>
          )}
          {!successValidatedPayment && (
            <i className={"fa fa-check-circle text-65xl text-green-520"} />
          )}
        </div>
      </div>
      {/* Only show successValidatedPayment for Paypal, Credit Card move to step 3 right after submit so no need show */}
      {successValidatedPayment === "PayPal" && (
        <div className="border-2 border-green-520 rounded flex justify-between px-15 py-5 mb-10 items-center">
          <div className="flex">
            <Icon
              icon="paypal"
              type="base"
              classNames="w-40 h-icon-base mr-15"
            />
            <p className="font-bold">{successValidatedPayment}</p>
          </div>
          <i className={"fa fa-check-circle text-65xl text-green-520"} />
        </div>
      )}
      <div className={`${isHiddenOptions ? "hidden" : ""}`}>
        <p className={`${isExpandedPopup ? "hidden" : "mb-10"}`}>
          {choose_way_to_pay_text.text}
        </p>
        <div className="border border-gray-90 rounded-5">
          {isLoading ? (
            <Spinner className="relative" />
          ) : (
            <>
              <div
                className={`border-gray-90 py-15 px-10 bg-gray-20 hover:bg-white hover:font-medium rounded-t-5 ${
                  isPaypal
                    ? "hidden"
                    : !isCreditCard
                    ? "border-b"
                    : "rounded-b-5"
                }`}
              >
                <div
                  onClick={handleOpenCreditCardPopup}
                  className={`cursor-pointer flex items-center ${
                    isExpandedPopup ? "hidden" : ""
                  }`}
                >
                  <i className={"fa fa-credit-card text-65l text-gray-800"} />
                  <p className="ml-25">{credit_card_option_label.text}</p>
                </div>
                <div
                  className={`${
                    isCreditCard
                      ? "flex justify-between items-center border-b border-gray-90 pb-10"
                      : "hidden"
                  }`}
                >
                  <div className="flex items-center">
                    <i className={"fa fa-credit-card text-65l text-gray-800"} />
                    <p className={"ml-25"}>{pay_with_credit_card_text.text}</p>
                  </div>
                  <div className="">
                    <Icon
                      icon="visa"
                      type="base"
                      classNames="w-40 h-icon-base"
                    />
                    <Icon
                      icon="mastercard"
                      type="base"
                      classNames="ml-10 w-40 h-icon-base"
                    />
                  </div>
                </div>
                {isCreditCard && (
                  <div className="m-auto flex flex-col w-fit-content justify-center items-center">
                    <form
                      id="chargify-form"
                      ref={chargifyForm}
                      onSubmit={handleSubmitCreditCardForm}
                    >
                      <div id="chargify-card-number" className="w-full"></div>
                      <div className="flex">
                        <div id="chargify-card-month" className="mr-5"></div>
                        <div id="chargify-card-year" className="mr-15"></div>
                        <div id="chargify-card-cvv"></div>
                      </div>
                      <div className="host-field">
                        <input
                          type="hidden"
                          className="host-input"
                          data-chargify="country"
                          value={currentCountry.toUpperCase()}
                        />
                      </div>
                      <button
                        type="submit"
                        className="hidden"
                        disabled={isProcessing}
                        id="submit-credit-card"
                      />
                    </form>
                  </div>
                )}
              </div>
              {!isDisabledPayPal && (
                <div
                  id="paypal-chargify-section"
                  className={`py-15 px-10 bg-gray-20 hover:bg-white hover:font-medium rounded-b-5 ${
                    isCreditCard
                      ? "hidden"
                      : isPaypal
                      ? "min-h-140 rounded-t-5"
                      : ""
                  }`}
                >
                  <div
                    onClick={handleOpenPaypalPopup}
                    className="cursor-pointer flex items-center"
                  >
                    <i className={"fa fa-paypal text-4xl text-gray-800"} />
                    <p className="ml-25">{paypal_option_label.text}</p>
                  </div>
                  <div
                    className={`border-gray-90 py-10 hover:bg-gray-10 ${
                      isPaypal ? "border-b" : "hidden"
                    }`}
                  />
                  <form
                    id="paypal-chargify-form"
                    className={`paypal-host-form ${
                      isPaypal ? "block" : "hidden"
                    }`}
                  >
                    <Spinner className="relative mt-10 -mb-52" />
                    <div
                      id="paypal-button"
                      className="w-full flex justify-center"
                      ref={paypalButtonRef}
                    ></div>
                    <div id="paypal-chargify"></div>
                  </form>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {!isDisabledPayPal && (
        <div
          onClick={handleOpenChooseOptions}
          className={`bg-gray-30 p-10 w-full mt-5 text-center underline text-gray-800 text-3xs hover:text-black hover:text-base hover:font-medium cursor-pointer ${
            isExpandedPopup ? "" : "hidden"
          }`}
        >
          {choose_another_way_text.text}
        </div>
      )}
    </div>
  )
}

export default ChargifyDropIn
